<template>
    <div class="main-content">
        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"></loading>
      <div class="row">
        <div class="col-3">
        </div>
        <div class="col-6">
          <div class="card">
            <div class="card-header mt-3">
              <h2>Generate Corporate Vouchers</h2>
            </div>
            <div class="card-body pt-5">

              <form method="post" action="#" v-on:submit.prevent="openVoucherPreview">
                <app-timeline>
                  <app-timeline-item variant="primary">
                    <div  class="mb-4 bg-primary p-1" >
                      <h5 class="text-white">Institution Information</h5>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-label" :class="{ 'text-danger': $v.voucher.customer.$error }">
                            Institution:
                          </label> <br/>
                          <select class="form-control" v-model.trim="$v.voucher.customer.$model" >
                            <option :value="institution" v-for="institution in institutions"> {{ institution.nameOfInstitution }}</option>
                          </select>
                          <div class="text-danger" v-if="!$v.voucher.customer.required && $v.voucher.customer.$dirty">Institution is required</div>
                        </div>


                        <!--                    <div class="input-group mb-2">
                                              <input v-model.trim="contactPersonPhoneNumber" type="text" class="form-control"  />
                                              <div style="cursor: pointer;" class="input-group-append" @click="searchCustomer">
                                                <span style="background-color:#9088F4;color:#fff;" class="input-group-text" >Find</span>
                                              </div>
                                            </div>

                                            <div v-if="customers.length > 0">
                                              <table class="table table-bordered">
                                                <tr>
                                                  <th>Select</th>
                                                  <th>Name</th>
                                                </tr>
                                                <tr v-for="customer in customers">
                                                  <td> <input type="radio" :value="customer" v-model="voucher.customerId" name="selected_customers" /> </td>
                                                  <td> {{ customer.nameOfInstitution }} </td>
                                                </tr>
                                              </table>
                                            </div>-->
                      </div>
                      <div class="col-md-6">
                        <div class="form-group" >
                          <label class="form-label" :class="{ 'text-danger': $v.voucher.redemptionOption.$error }">Redemption Options:</label> <br/>
                          <div >
                            <label >
                              <input name="redemptionOption"  v-model.trim="$v.voucher.redemptionOption.$model" type="radio" value="any" /> Any Outlet
                            </label>
                          </div>
                          <div >
                            <label>
                              <input name="redemptionOption" v-model.trim="$v.voucher.redemptionOption.$model" type="radio" value="specific" /> Specific
                            </label>
                          </div>

                          <div class="text-danger" v-if="!$v.voucher.redemptionOption.required && $v.voucher.redemptionOption.$dirty">Redemption option is required</div>

                          <div v-if="voucher.redemptionOption == 'specific'" >
                            <label class="form-label" :class="{ 'text-danger': $v.voucher.outlets.$error }">
                              Outlet:
                            </label>
                            <v-select
                                label="name"
                                v-model.trim="$v.voucher.outlets.$model"
                                dir="ltr"
                                multiple
                                :options="outlets"
                            />
                          </div>
                          <div class="text-danger" v-if="!$v.voucher.outlets.required && $v.voucher.outlets.$dirty">Please select redemption outlets</div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-3">
                      <div class="col-md-6">
                        <div class="form-group ">
                          <label class="form-label" >Message:</label> <br/>
                          <div >
                            <textarea class="form-control" rows="5" v-model="voucher.message"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <label class="form-label">Voucher Recipient Data</label> <br/>
                        <input class="form-control" type="file"  ref="voucherRecipientData" @change="handleFileSelectedTaxFile"  /> <a  href="/VoucherRecipientsCorporateTemplate.xlsx">Download Sample File</a>
                      </div>
                    </div>
                  </app-timeline-item>
                  <app-timeline-item variant="warning">
                    <div  class="mb-4 bg-warning p-1" >
                      <h5 class="text-white">Payment</h5>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-label" :class="{ 'text-danger': $v.voucher.amount.$error }">
                            Amount:
                          </label> <br/>
                          <input type="number" class="form-control" v-model.trim="$v.voucher.amount.$model"  />
                          <div class="text-danger" v-if="!$v.voucher.amount.required && $v.voucher.amount.$dirty">Amount is required</div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-label"  :class="{ 'text-danger': $v.voucher.paymentMode.$error }">
                            Payment Mode:
                          </label> <br/>
                          <label >
                            <input name="paymentOption" v-model.trim="$v.voucher.paymentMode.$model" type="radio" value="MPESA" /> Mpesa
                          </label> &nbsp;&nbsp;
                          <label >
                            <input name="paymentOption" v-model.trim="$v.voucher.paymentMode.$model" type="radio" value="BANK" /> Bank
                          </label>&nbsp;&nbsp;
                          <label >
                            <input name="paymentOption" v-model.trim="$v.voucher.paymentMode.$model" type="radio" value="CASH" /> Cash
                          </label>
                          <label>
                            <input name="paymentOption" v-model.trim="$v.voucher.paymentOption.$model" type="radio" value="CARD" /> Card
                          </label>
                          <div class="text-danger" v-if="!$v.voucher.paymentMode.required && $v.voucher.paymentMode.$dirty">Payment mode is required</div>
                        </div>
                        <div v-if="voucher.paymentMode == 'MPESA'">
                          <div class="input-group mb-2">
                            <input v-model.trim="$v.voucher.mpesaPhoneNumber.$model" type="text" class="form-control"  />
                            <div style="cursor: pointer;" class="input-group-append" @click="promptCustomerPayment">
                              <span style="background-color:#9088F4;color:#fff;" class="input-group-text" >Push STK</span>
                            </div>
                          </div>
                          <div class="text-danger" v-if="!$v.voucher.mpesaPhoneNumber.required && $v.voucher.mpesaPhoneNumber.$dirty">Mpesa phone number is required</div>
                        </div>

                        <div class="form-group" v-if="voucher.paymentMode == 'BANK' || voucher.paymentMode == 'MPESA' ">
                          <label class="form-label" :class="{ 'text-danger': $v.voucher.transactionReference.$error }" >
                            Transaction Reference:
                          </label>
                          <input v-model.trim="$v.voucher.transactionReference.$model" class="form-control" type="text" />
                          <div class="text-danger" v-if="!$v.voucher.transactionReference.required && $v.voucher.transactionReference.$dirty">Transaction reference is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-label" :class="{ 'text-danger': $v.voucher.transactedBy.$error }">
                            Transacted By:
                          </label> <br/>
                          <input type="text" class="form-control" v-model.trim="$v.voucher.transactedBy.$model"  />
                          <div class="text-danger" v-if="!$v.voucher.transactedBy.required && $v.voucher.transactedBy.$dirty">Transacted by field is required</div>
                        </div>
                      </div>
                    </div>
                  </app-timeline-item>
                </app-timeline>

                <div class="row">
                  <div class="col-md-12">
                    <hr/>
                  </div>
                </div>



                <div class="row mt-3">
                  <div class="col-md-12">
                    <button class="btn btn-success float-right" type="submit"> Submit </button>
                  </div>
                </div>

              </form>

            </div>
          </div>
        </div>
        <div class="col-3">
        </div>
      </div>

      <b-modal id="generate-voucher-preview" title="Voucher Review" @cancel="true" @ok="saveImportedFile"   centered>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label" >
                Institution:
              </label> <br/>
               {{ voucher.customer.nameOfInstitution }}
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group" >
              <label class="form-label" >Redemption Options:</label> <br/>
              <div >
                {{ voucher.redemptionOption }}
              </div>

              <div v-if="voucher.redemptionOption == 'specific'" >
                <ul>
                  <li v-for="voucherOutlet in voucher.outlets">{{ voucherOutlet.name }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="form-group ">
              <label class="form-label" >Message:</label> <br/>
              <div >
                {{ voucher.message }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label" >
                Amount:
              </label> <br/>
              {{ voucher.amount }}
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label"  >
                Payment Mode:
              </label> <br/>
              {{ voucher.paymentMode }}
            </div>

            <div class="form-group" v-if="voucher.paymentMode == 'BANK' || voucher.paymentMode == 'MPESA' ">
              <label class="form-label"  >
                Transaction Reference:
              </label>
              {{ voucher.transactionReference }}
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label" >
                Transacted By:
              </label> <br/>
              {{ voucher.transactedBy }}
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal id="stk-push-wait" title="Payment Confirmation" @cancel="cancelSTKPush"   centered>
        <div class="text-center">
          <h2>
            Please wait as we verify customer payment
          </h2>
        </div>

        <div class="text-center">
          <h2 class="text text-danger"> Retry in {{ stkPushCounter }}</h2>
        </div>

      </b-modal>
    </div>
</template>

<script>

    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css'
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    require('vue-toastr/src/vue-toastr.scss');
    import { mapGetters, mapActions } from "vuex";
    import {FormWizard, TabContent} from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import CodeInput from "vue-verification-code-input";
    import vSelect from "vue-select";
    import Table from "@/views/table/bs-table/Table.vue";
    import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
    import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
    import { required,requiredIf} from 'vuelidate/lib/validators'

    export default {
        name: 'gift-cards',
        data: () => ({
          cancelSTKPush: false,
          contactPersonPhoneNumber:"",
          mpesaPhoneNumber:"",
          fileUpload:"",
            customers:[],
            table_loading:true,
            tickets:"",
            selected: [],
            isLoading: false,
            fullPage: true,
            loading:false,
             outlets:[],
            csrf: "",
            voucher:{
              customer:{
                nameOfInstitution:""
              },
              customerId:"",
              amount:"",
              message:"",
              redemptionOption:"any",
              outlets:[],
              transactionReference:"",
              voucherRecipients:[],
              transactedBy:"",
              mpesaPhoneNumber:"",
              paymentMode:""
            },
           institutions:[],
           stkPushCounter: 30,
        }),
        mounted() {
          this.getOutletsData();
          this.getInstitutionalCustomersData();
        },
        methods:{
          ...mapActions(["checkMpesaTransaction","pushSTK","getVouchers", "getOutlets","importVoucherRecipients","searchCustomerByPhoneNumber","buyInstitutionalVouchers","getInstitutionalCustomers"]),
          openVoucherPreview() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
              this.$bvModal.show('generate-voucher-preview');
            }
          },
          handleFileSelectedTaxFile(){
            this.fileUpload = this.$refs.voucherRecipientData.files[0];
          },
          saveImportedFile(){
            if(this.fileUpload){
              let self = this;
              this.isLoading = true;

              let formData = new FormData();
              formData.append('excelFile', this.fileUpload);

              this.importVoucherRecipients(formData).then((response) => {
                self.isLoading = false;

                self.voucherOutletId = 0;

                self.voucher.voucherRecipients = response.voucherRecipientsDTOS;

                self.saveVoucher();

                //self.getOutletData();

              })
                  .catch(error => {

                    console.log(error)

                    self.isLoading = false;

                    self.$bvToast.toast(error.message, {
                      title: 'Error',
                      variant: 'danger',
                      autoHideDelay: 5000,
                      appendToast: false
                    });

                  })

            } else {
              this.$bvToast.toast("Please upload file to proceed", {
                title: 'Error',
                variant: 'danger',
                autoHideDelay: 5000,
                appendToast: false
              });
            }
          },

          saveVoucher(){
            let self = this;

              this.isLoading = true;
              this.voucher.customerId = this.voucher.customer.id

              this.voucher.outlets =this.voucher.outlets.map(function (outlet){
                return outlet.id;
              });

              this.buyInstitutionalVouchers(this.voucher).then(() => {

                self.isLoading = false;

                self.$bvToast.toast("Voucher(s) generated successfully.", {
                  title: 'Success',
                  variant: 'success',
                  autoHideDelay: 5000,
                  appendToast: false
                });

                self.$router.push("/apps/vouchers");

              })
                  .catch(error => {

                    console.log(error)

                    self.isLoading = false;

                    self.$bvToast.toast(error.message, {
                      title: 'Error',
                      variant: 'danger',
                      autoHideDelay: 5000,
                      appendToast: false
                    });

                  })
          },
          searchCustomer(){
            let self = this;
            this.isLoading = true;

            this.searchCustomerByPhoneNumber({phone_number: this.contactPersonPhoneNumber})
                .then(function (customers) {
                  self.isLoading = false;
                  self.customers = customers;
                })
                .catch(function (error) {
                })
          },
          promptCustomerPayment() {
            let self = this;
            this.isLoading = true;

            let paymentData = {
              phoneNumber:this.voucher.mpesaPhoneNumber,
              billRef:"INST-"+this.institutions.length+1,
              amount:this.voucher.amount
            }

            this.pushSTK(paymentData)
                .then(function (response) {
                  self.stkPushCounter = 30;
                  self.isLoading = false;
                  self.$bvModal.show('stk-push-wait');
                  self.startTransactionCheck();
                  self.startSTKCountDownTimer();
                })
                .catch(function (error) {
                  console.log(error)
                })

          },
          getVouchersByOutlet() {
            let self = this;
            this.isLoading = true;

            this.getOutletVoucherCodes({page: this.paginationVoucherCodes.current_page, fromDate:"all",toDate:"all", outletId:this.voucher.voucherOutletId, status: "NOT_UTILISED"})
                .then(function (outletVouchers) {
                  self.isLoading = false;
                  self.unutilisedVouchers = outletVouchers.content;

                })
                .catch(function (error) {
                  self.isLoading = false;

                })
          },
          getOutletVoucherCodesData() {
            let self = this;
            this.getOutletVoucherCodes({page: this.paginationVoucherCodes.current_page, fromDate:this.fromDateVoucherCode,toDate:this.toDateVoucherCode, outletId:this.voucherOutletId, status: this.voucherStatus})
                .then(function (outletVouchers) {
                  self.outletVouchers = outletVouchers.content;
                  self.paginationVoucherCodes.current_page = outletVouchers.number;
                  self.paginationVoucherCodes.total = outletVouchers.totalElements;
                  self.paginationVoucherCodes.per_page = outletVouchers.numberOfElements;
                  self.paginationVoucherCodes.from = outletVouchers.pageable.offset + 1 ;
                  self.paginationVoucherCodes.to = outletVouchers.pageable.pageSize;
                  self.paginationVoucherCodes.last_page = outletVouchers.totalPages;
                })
                .catch(function (error) {

                })
          },
          getOutletsData(){
              let self = this;

              this.getOutlets()
                  .then(function (outlets) {
                    self.outlets = outlets;

                  })
                  .catch(function (error) {
                    if( typeof error.response.status != "undefined"){
                      if(error.response.status == 401){
                        location.replace("/login");
                      }

                    }
                  })
            },
          getInstitutionalCustomersData() {
            let self = this;

            this.getInstitutionalCustomers()
                .then(function (institutions) {
                  self.institutions = institutions;

                })
                .catch(function (error) {

                })
          },
          startTransactionCheck(){
            let self = this;

            this.intervalId =  setInterval(function (){self.checkMpesaTransaction({billRef: "INST-"+self.institutions.length}).then((response) => {

              if(response.statusCode == 200) {
                self.transactionReference = response.message
                clearInterval(self.intervalId);

                self.isLoading = false;

                this.$bvModal.hide('stk-push-wait');

                self.$toast.open({
                  message: "Payment Successful!",
                  type: 'success',
                  duration:5000,
                  position:"top-right"
                });

              } else if(self.stkPushCounter < 1) {
                clearInterval(self.intervalId);

                this.$bvModal.hide('stk-push-wait');

                self.$toast.open({
                  message: "Payment check failed. Please try again",
                  type: 'success',
                  duration:5000,
                  position:"top-right"
                });
              }
            }).catch(error => {

              if(self.stkPushCounter < 1) {
                clearInterval(self.intervalId);

                this.$bvModal.hide('stk-push-wait');

                self.$toast.open({
                  message: "Payment check failed. Please try again",
                  type: 'success',
                  duration:5000,
                  position:"top-right"
                });
              }

              console.log(error);
            })}, 5000);
          },
          startSTKCountDownTimer() {
            let self = this;
            setInterval(function() {
              if(self.stkPushCounter > 0) {
                self.stkPushCounter--
              }

            },1000)
          }
        },
        validations: {
          voucher:{
            customer:{
              required
            },
            amount:{
              required
            },
            redemptionOption:{
              required
            },
            paymentMode:{
              required
            },
            outlets:{
              required: requiredIf(function () {
                return this.voucher.redemptionOption != 'any'
              })
            },
            transactionReference:{
              required: requiredIf(function () {
                return this.voucher.paymentMode != 'CASH'
              })
            },
            mpesaPhoneNumber:{
              required: requiredIf(function () {
                return this.voucher.paymentMode == 'MPESA'
              })
            },
            transactedBy:{
              required
            }
          }
        },
        components:{
          AppTimelineItem, AppTimeline,
          Table,
          vSelect,
          CodeInput,
          FormWizard,
          TabContent,
          Loading,
          DatePicker
        }
    }
</script>

<style >

.vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  background-color: transparent !important;
}
.vue-form-wizard.sm .wizard-nav-pills>li.active>a .stepTitle{
  color:#4AB5E6 !important;
}


.vue-form-wizard .wizard-tab-content {
  padding-top: 50px;
}
.stepTitle{
  margin-top: -67px;
  color:#4AB5E6;
  font-weight: 600;
}
.vue-form-wizard.sm .wizard-icon-circle{
  height: 20px;
  width: 20px;
  background-color: #4AB5E6;
  border: none;
}
.vue-form-wizard.sm .wizard-navigation .wizard-progress-with-circle{
  top:14px;
  left:15.5%;
  width: 69.5% !important;
  background-color: #4AB5E6 !important;
  height: 2px !important;
}
table tr th{
  padding-top: 10px;
  padding-bottom: 10px;
}
fieldset.custom-border {
  border: 1px groove #ddd !important;
  padding: 0 1.4em 1.4em 1.4em !important;
  margin: 0 0 1.5em 0 !important;
  -webkit-box-shadow: 0px 0px 0px 0px #000;
  box-shadow: 0px 0px 0px 0px #000;
}

legend.custom-border {
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}
.vue-step-wizard{
  width: 100% !important;
}
.tabStatus{
  background-color: #002563 !important;
}
.tabLabel{
  font-weight: bolder;
}

label.form-label {
  font-size: 14px;
  font-weight: 700;
}

.vue-form-wizard .wizard-navigation .wizard-progress-with-circle .wizard-progress-bar{
  width: 0% !important;
}
.vue-form-wizard .navbar .navbar-nav>li>a.wizard-btn, .vue-form-wizard .wizard-btn{
  background-color: #4AB5E6 !important;
  color:#000 !important;
  border: none;
  margin-top: 20px;

}

table td{
  padding: 10px;
}
</style>

